import React, { useState, useEffect } from "react";
import config from "../../../Config";
import axios from "axios";
import moment from "moment";
import SupportMenu from "./SupportMenu";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';
import LoginCheck from "../General/LoginCheck";
import Pagination from "../../Dashboard/General/Pagination";
import { ArrowLeftIcon, ChevronRightIcon, FaceFrownIcon } from "@heroicons/react/24/outline";

import TicketsDetail from "./TicketsDetail";
import { toast } from "react-toastify";

function InboxCounter(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [tickets, setTickets] = useState([]);
    const [typeName, settypeName] = useState("");
    const [loading, setLoading] = useState(false);


    // pagination
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);

    const [TicketID, setTicketId] = useState(0);

    const navigate = useNavigate();


    useEffect(() => {

        setLoading(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            pageNo: pageNo,
            pageSize: pageSize,
            TypeID: props.id,
            Status: "Pending",

        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'Support/GetSupportTicketByType',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                if (response.data.status_code == 1) {
                    setTickets(response.data.ListofTickets);
                    setTotalRecords(response.data.totalRecords);
                    settypeName(response.data.typeName);
                    setLoading(false);

                } else if (response.data.status_message === "Invalid Access Key.") {
                    navigate("/login");
                    localStorage.clear();
                }
                else {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    setLoading(false);
                }

            })
            .catch(function (error) {
                console.log(error);
            });



    }, [pageNo, pageSize]);


    const handlePageChange = (page) => {
        setPageNo(page);
    };

    const renderLogs = () => {
        // if (tickets != null) {
        if (tickets && tickets.length > 0) {
            // let type = params.type;
            // let newtickets = tickets.filter(ticket => ticket.Status == type);
            let count = 1;
            return (
                tickets.map((item, index) => {

                    return (
                        <>
                            <li key={index}>
                                <h2>
                                    {item.Subject}
                                </h2>
                                <div className="ticket-id">{item.TicketID}</div>
                                <div className="d-flex mt-2 mb-2 justify-content-between">
                                    <div>{item.TicketType}</div>
                                    <div>{moment(item.Dated).format('DD MMM, YYYY HH:mm:ss')}</div>
                                </div>
                                <div>
                                    <button type="button" onClick={(e) => handleTicketDetail(item.ID)} className="button button-icon-bs button-outline-gray">
                                        Check Status <ChevronRightIcon />
                                    </button>
                                </div>
                            </li>


                        </>
                    )
                })
            )
        }
        else {
            return (
                <>
                    <div className="else">
                        <FaceFrownIcon />
                        <h4>No Data Found</h4>
                    </div>
                </>
            )
        }
    }


    const BackToInbox = () => {
        props.BackToInbox();
    }

    const handleTicketDetail = (TicketID) => {
        setTicketId(TicketID);
    }

    return (
        <>
            <LoginCheck />
            {
                TicketID == 0 &&
                <div className="tickte-detail-head">
                    <div>
                        <button onClick={BackToInbox}><ArrowLeftIcon /> </button>
                        <h3>{typeName}</h3>
                    </div>
                    <span>{tickets.length}</span>
                </div>
            }

            {
                loading ?
                    <div className="p-5">
                        <Spinner animation="border" role="status"></Spinner>
                    </div>
                    :
                    TicketID == 0 ?
                        <ul className="list-support-tickets">
                            {renderLogs()}
                        </ul>
                        :
                        <TicketsDetail id={TicketID} GackToOpen={BackToInbox} BackToOpen={BackToInbox} />


            }
            {/* {totalRecords > 10 && (
                <div className="pagination-wrap">
                    <div className="row align-items-center">
                        <div className="col-md-2 form_default">
                            <div className="d-flex align-items-center">
                                <label className="me-2" style={{ fontSize: "15px" }}>
                                    Page size
                                </label>
                                <div className="form-group">
                                    <select
                                        className="form-select"
                                        style={{ width: "100px" }}
                                        onChange={(e) => {
                                            setPageNo(1);
                                            setPageSize(parseInt(e.target.value));
                                        }}
                                    >
                                        <option value="10" selected>
                                            10
                                        </option>
                                        <option value="20">20</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="500">500</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <Pagination
                            pageNo={pageNo}
                            pageSize={pageSize}
                            totalRecords={totalRecords}
                            onPageChange={handlePageChange}
                        />
                    </div>
                </div>
            )} */}

        </>
    );
}

export default InboxCounter;