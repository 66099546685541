import React, { useState, useEffect } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import config from "../../Config";
import axios from "axios";
import logo from "../../assets/images/logo/mag.svg";
import background from "../../assets/images/partner/login-bg.png";
import { toast, ToastContainer } from "react-toastify";
import { Form, Spinner } from "react-bootstrap";
import {
  ArrowLeftStartOnRectangleIcon,
  EnvelopeIcon,
  EyeIcon,
  EyeSlashIcon,
  UserCircleIcon,
  XCircleIcon,
  CheckCircleIcon,
  KeyIcon,
} from "@heroicons/react/24/outline";
import ReCAPTCHA from "react-google-recaptcha";
const PartnerSignUp = () => {
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [Username, setUsername] = useState("");
  const [UserNameExist, setUserNameExist] = useState(null);
  const [ReferrralExist, setReferrralExist] = useState(null);
  const [Referrral, setReferrral] = useState("");
  const [RefEmail, setRefEmail] = useState(null);
  const [ParentID, setParentID] = useState(0);

  let navigate = useNavigate();
  let params = useParams();

  const [VerificationCode, setVerificationCode] = useState("");
  const [Email, setEmail] = useState("");
  const [inputdisable, setinputdisable] = useState(false);
  const [inputloading, setinputloading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [inputstyle, setinputstyle] = useState(true);
  const [timer, setTimer] = useState(false);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isPasswordValid, setPasswordValid] = useState(true);



  const [captchaValue, setCaptchaValue] = useState(null);

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  }



  useEffect(() => {
    if (params.id) {
      setParentID(params.id);

      const data = {
        UserName: params.id,
      };

      var api_config = {
        method: "post",
        url: config.base_url + "Members/GetReferralUserByUserName",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(api_config)
        .then(function (response) {
          // console.log(response.data);
          if (response.data.status_code == 1) {
            setRefEmail(response.data.User_Name);
            setReferrralExist(true);
          } else if (response.data.status_code == 0) {
            setReferrralExist(false);
            toast.error(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch(function (error) {
          toast.error("Something went wrong..", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    CheckReferrral();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      if (captchaValue) {


        if (password != confirmPassword) {

          toast.error('Password didn\'t match', {
            position: toast.POSITION.TOP_RIGHT
          });
          return false;
        }
        setLoading(true);

        const data = {
          Full_Name: event.target.Full_Name.value,
          Email_Address: Email,
          User_Name: Username,
          Referrer_Email: event.target.Referrer_Email.value,
          Password: password,
          Email_Verification_Code: VerificationCode,
        };

        // console.log(data);

        var api_config = {
          method: "post",
          url: config.base_url + "Members/SignUp",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };

        // console.log(data);

        axios(api_config)
          .then(function (response) {
            // console.log(response.data);

            if (response.data.status_code == 1) {
              toast.success(response.data.status_message, {
                position: toast.POSITION.TOP_RIGHT,
              });

              setTimeout(() => {
                navigate("/login");
              }, 4000);
            } else if (response.data.status_code == 0) {
              toast.error(response.data.status_message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
            setLoading(false);
          })
          .catch(function (error) {
            setLoading(false);
            toast.error("Something went wrong..", {
              position: toast.POSITION.TOP_RIGHT,
            });
          });
      } else {
        toast.error("Please complete the CAPTCHA", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }

    setValidated(true);
  };
  const CheckReferrral = (e) => {
    if (Referrral.length > 0) {
      const data = {
        ReferralName: Referrral,
      };

      var api_config = {
        method: "post",
        url: config.base_url + "Members/CheckValidReferral",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(api_config)
        .then(function (response) {
          if (response.data.status_message == "Valid Referral") {
            setReferrralExist(true);
          } else {
            setReferrralExist(false);
          }
        })
        .catch(function (error) {
          // console.log(error);
        });
    } else if (Referrral.length == 0) {
      setReferrralExist(null);
    }
  };

  const checkUsername = () => {
    if (Username == " " || Username == "") {
      setUserNameExist(true);
      return false;
    }

    const data = {
      UserName: Username,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Members/CheckUserNameExists",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          // "User Does Not Exists"
          setUserNameExist(false);
        } else {
          // "User Name Exists"
          setUserNameExist(true);
        }
      })
      .catch(function (error) {
        // console.log(error);
      });
  };

  const handleUsernameChange = (event) => {
    const value = event.target.value.slice(0, 13);
    const sanitizedValue = value.replace(/[^a-zA-Z0-9]/g, "");
    setUsername(sanitizedValue);

    const formattedUsername =
      sanitizedValue.charAt(0).toUpperCase() +
      sanitizedValue.slice(1).toLowerCase();
    setUsername(formattedUsername);
  };
  const handleReferralChange = (event) => {
    const value = event.target.value.slice(0, 13);
    const sanitizedValue = value.replace(/[^a-zA-Z0-9]/g, ""); // Remove spaces
    setReferrral(sanitizedValue);
  };


  const handleChange = (event) => {
    const value = event.target.value;
    setPassword(value.slice(0, 18));

    if (validatePassword(value)) {
      setPasswordValid(true);
    } else {
      setPasswordValid(false);
    }
  };

  const isUppercaseLetterPresent = (password) => {
    const uppercaseRegex = /[A-Z]/;
    return uppercaseRegex.test(password);
  };

  const isNumberPresent = (password) => {
    const numberRegex = /\d/;
    return numberRegex.test(password);
  };

  const validatePassword = (password) => {
    const passwordRegex =
      /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{8,}$/;
    return passwordRegex.test(password);
  };

  const handleEmailCheck = (event) => {

    if (Email != null && Email.length > 0) {

      setinputstyle(true);
      setinputloading(true);
      const data = {
        Email_Address: Email,
      };

      // console.log(data);

      var api_config = {
        method: 'post',
        url: config.base_url + 'Members/CheckWithOtpforValidSignUpEmail',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios(api_config)
        .then(function (response) {

          // console.log(response.data);
          if (response.data.status_code == 1) {
            setinputloading(false);
            setinputdisable(true);
            setIsButtonDisabled(true);

            setTimeout(() => {
              setIsButtonDisabled(false);
            }, 60000);
            setTimer(60);

            toast.success(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT
            });
          }
          else {
            setinputloading(false);
            setinputstyle(false);
            setinputdisable(false);
            toast.error(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT
            });
          }
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          toast.error('Network Error..', {
            position: toast.POSITION.TOP_RIGHT
          });
        });
    }
    else {
      setinputstyle(false);
    }
  };


  return (
    <>
      <ToastContainer />
      <section className="business-signin-form signup-page">
        <img src={background} />
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <div className="container">
            <div className="login_box">
              <div className="login_box_head">
                <img src={logo} />
                <h4>Create New Customer Account</h4>
              </div>
              <div className="login_box_body">
                <div className="icon-input">
                  <input
                    name="Full_Name"
                    placeholder="Full Name"
                    className=""
                    required
                  />
                </div>
                <div className="icon-input">
                  <input
                    name="User_Name"
                    type="text"
                    placeholder="Username"
                    className=""
                    required
                    onChange={(e) => handleUsernameChange(e)}
                    onBlur={checkUsername}
                    value={Username}
                  />
                  <div className="icon">
                    {UserNameExist == true ? (
                      <>
                        <XCircleIcon />
                      </>
                    ) : UserNameExist == false ? (
                      <>
                        <CheckCircleIcon />
                      </>
                    ) : (
                      <UserCircleIcon />
                    )}
                  </div>
                </div>
                {Username.length < 8 && Username.length > 0 && (
                  <div className="">
                    <p
                      style={{
                        fontSize: "12px",
                        margin: "10px 0",
                        color: "red",
                      }}
                    >
                      Minimum 8 to 13 characters
                    </p>
                  </div>
                )}
                <div className="icon-input">
                  <input
                    type="email"
                    placeholder="Email"
                    name={Email}
                    onChange={(e) => setEmail(e.target.value)}
                    className=""
                    required
                    style={{ paddingRight: '106px' }}
                  />
                  <div className="icon">
                    <EnvelopeIcon />
                  </div>
                </div>
                <div className="icon-input  mb-4">
                  <input
                    type="text"
                    onChange={(e) => setVerificationCode(e.target.value)}
                    name={VerificationCode}
                    placeholder="Email Verification Code"
                    className="form-control"
                    required
                  />
                  <div className="icon">
                    <button type="button"
                      disabled={isButtonDisabled}
                      style={{width:'fit-content',border:'50px',fontWeight:'400',textTransform:'none',boxShadow:'none'}}
                      onClick={() => handleEmailCheck()} className="button small-button">
                      Get Code
                    </button>
                  </div>
                </div>
                {RefEmail == null ? (
                  <div className="icon-input">
                    <input
                      type="text"
                      name="Referrer_Email"
                      value={Referrral}
                      onBlur={CheckReferrral}
                      onChange={(e) => handleReferralChange(e)}
                      placeholder="Referral"
                    />
                    <div className="icon">
                      {ReferrralExist == true ? (
                        <CheckCircleIcon />
                      ) : ReferrralExist == false ? (
                        <XCircleIcon />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="icon-input">
                    <input
                      type="text"
                      className="disabled"
                      name="Referrer_Email"
                      value={RefEmail}
                      placeholder="Referral"
                      required
                      disabled
                    />
                    <div className="icon">
                      {ReferrralExist == true ? (
                        <CheckCircleIcon />
                      ) : ReferrralExist == false ? (
                        <XCircleIcon />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                )}

                {/* Password Input */}
                <div className="icon-input">
                  <input
                    type={showPassword ? "text" : "password"}
                    onChange={handleChange}
                    name="password"
                    value={password}
                    placeholder="Password"
                    className=""
                    required
                  />
                  <div className="icon" onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <EyeSlashIcon /> : <EyeIcon />}
                  </div>
                </div>

                {/* Password Validation Messages */}
                {password && !isPasswordValid && (
                  <ul className="password-validation-message">
                    <li className={password.length >= 8 && password.length <= 18 ? "completed" : "uncompleted"}>
                      Minimum 8 to 18 characters
                    </li>
                    <li className={isUppercaseLetterPresent(password) ? "completed" : "uncompleted"}>
                      At least one uppercase letter
                    </li>
                    <li className={isNumberPresent(password) ? "completed" : "uncompleted"}>
                      At least one number
                    </li>
                    <li className={/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(password) ? "completed" : "uncompleted"}>
                      At least one special character
                    </li>
                  </ul>
                )}

                {/* Confirm Password Input */}
                <div className="icon-input">
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    value={confirmPassword}
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    className=""
                    required
                  />
                  <div className="icon" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                    {showConfirmPassword ? <EyeSlashIcon /> : <EyeIcon />}
                  </div>
                </div>

                <p className="terms">
                  I agree to the
                  <a
                    className="link"
                    target="_blank"
                    href="https://www.mag.holdings/en/terms-of-service/"
                  >
                    {" "}
                    Terms & Condition
                  </a>
                  <a
                    className="link"
                    target="_blank"
                    href="https://www.mag.holdings/en/privacy-policy"
                  >
                    {" "}
                    Privacy Policy
                  </a>
                </p>
                <div className="mb-3">

                  <ReCAPTCHA
                    theme="light"
                    sitekey="6LfnFz0qAAAAAAblPmVjM6m4G_oG_4DxLAAtY6UE"
                    onChange={handleCaptchaChange}
                  />
                </div>
                <button
                  type="submit"
                  style={{ position: "relative", zIndex: 2 }}
                  className="button button-bs w-100"

                  disabled={!isPasswordValid || loading}
                >
                  Register {loading ? "..." : ""}{" "}
                  <span>
                    <ArrowLeftStartOnRectangleIcon />
                  </span>
                </button>
                <div className="mt-3 d-flex m-auto justify-content-center">
                  <p className="m-0 fs-6">
                    I already have an account
                    <NavLink to={"/login"} className="fs-6">
                      SIGN IN
                    </NavLink>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </section>
    </>
  );
};

export default PartnerSignUp;
