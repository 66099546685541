import React, { useState, useEffect, useRef } from "react";
import config from "../../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate, useParams } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import AdminRightsCheck from "../AdminRightsCheck";
import { Editor } from "@tinymce/tinymce-react";
import { ArrowUpTrayIcon, TrashIcon, ViewfinderCircleIcon } from "@heroicons/react/24/outline";
import { Cropper } from "react-cropper";

const UpdatePackage = () => {

  const [AccessKey, setAccessKey] = useState(
    localStorage.getItem("AdminAccessKey")
  );

  const [showFilters, setShowFilters] = useState(true);
  const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));
  const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
  const [isUpdated, setIsUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listOfRecord, setListOfRecord] = useState([]);

  const [Name, setName] = useState('');
  const [Amount, setAmount] = useState('');
  const [ROI, setROI] = useState('');
  const [PackageType, setPackageType] = useState('');
  const [NoofDays, setNoofDays] = useState('');
  const [TagLine, setTagLine] = useState('');
  const [DirectBonus, setDirectBonus] = useState('');
  const [DailyProfitReturn, setDailyProfitReturn] = useState('');
  const [MonthlyProfit, setMonthlyProfit] = useState('');
  const [TotalProfitReturn, setTotalProfitReturn] = useState('');
  const [ProductDelivery, setProductDelivery] = useState('');
  const [SortOrder, setSortOrder] = useState('');
  const [GetPackage, setGetPackage] = useState({});
  const [ShowPackage, setShowPackage] = useState();
  const [Details, setDetails] = useState('');
  // loadings
  const [LoadingPackage, setLoadingPackage] = useState(false);


  let navigate = useNavigate();
  let params = useParams();


  const DetailRef = useRef(null);
  const [Detail, setDetail] = useState("");
  const handleDetailChange = (content) => {
    setDetail(content);
  };

  const UpdatePackage = () => {
    document.title = "Package Setting Update";

    setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      ID: params.id,
      Name: Name,
      Amount: Amount,
      ROI: ROI,
      PackageType: PackageType,
      NoofDays: NoofDays,
      TagLine: TagLine,
      DirectBonus: DirectBonus,
      DailyProfitReturn: DailyProfitReturn,
      MonthlyProfit: MonthlyProfit,
      TotalProfitReturn: TotalProfitReturn,
      ProductDelivery: ProductDelivery,
      Details: Detail,
      SortOrder: SortOrder,
      ShowPackage: ShowPackage,
    };


    var SliderImage1 = frontCropper.getCroppedCanvas().toDataURL();
    var SliderImage2 = backCropper.getCroppedCanvas().toDataURL();
    data.SliderImage1 = SliderImage1.substring(22);
    data.SliderImage2 = SliderImage2.substring(22);

    var SliderImage3 = LicenceFrontCropper.getCroppedCanvas().toDataURL();
    var SliderImage4 = LicenceBackCropper.getCroppedCanvas().toDataURL();

    data.SliderImage3 = SliderImage3.substring(22);
    data.SliderImage4 = SliderImage4.substring(22);

    var Image = PassportCropper.getCroppedCanvas().toDataURL();
    data.Image = Image.substring(22);

    var api_config = {
      method: "post",
      url: config.base_url + "Package/UpdatePackageDetail",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    console.log(data);

    axios(api_config)
      .then(function (response) {
        console.log("data", response.data);
        if (response.data.status_code === 1) {
          toast.success(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setListOfRecord(response.data.ListofRecords);
          setLoading(false);
        }
        else if (response.data.status_code === 0 && response.data.status_message === "Invalid Access Key." || response.data.status_code === 2) {
          toast.success(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }

      })
      .catch(function (error) {
        // console.log(error);
      });
  };


  useEffect(() => {

    setLoadingPackage(true);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      Member_Type: MemberType,
      ID: params.id,
    };
    // console.log(data);
    var api_config = {
      method: 'post',
      url: config.base_url + 'Package/GetPackagebyID',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };


    axios(api_config)
      .then(function (response) {
        console.log(response.data)
        if (response.data.status_code == 1) {
          setGetPackage(response.data.Package);
          setLoadingPackage(false);
          setName(response.data.Package.Name);
          setAmount(response.data.Package.Amount);
          setROI(response.data.Package.ROI);
          setPackageType(response.data.Package.PackageType);
          setNoofDays(response.data.Package.NoofDays);
          setTagLine(response.data.Package.TagLine);
          setDirectBonus(response.data.Package.DirectBonus);
          setDailyProfitReturn(response.data.Package.DailyProfitReturn);
          setMonthlyProfit(response.data.Package.MonthlyProfit);
          setTotalProfitReturn(response.data.Package.TotalProfitReturn);
          setProductDelivery(response.data.Package.ProductDelivery);
          setDetails(response.data.Package.Details);
          setSortOrder(response.data.Package.SortOrder);
          setShowPackage(response.data.Package?.ShowPackage);

          setPassportSideSelected(true);
          setImgPassport(config.root + 'Images/Packages/' + response.data.Package.Image);
          setPassportCropper(config.root + 'Images/Packages/' + response.data.Package.Image);

          setLicenceFrontSideSelected(true);
          setLicenceFrontCropper(config.root + 'Images/Packages/' + response.data.Package.SliderImage3);
          setImgLicenceFront(config.root + 'Images/Packages/' + response.data.Package.SliderImage3);

          setLicenceBackSideSelected(true);
          setImgLicenceBack(config.root + 'Images/Packages/' + response.data.Package.SliderImage4);
          setLicenceBackCropper(config.root + 'Images/Packages/' + response.data.Package.SliderImage4);

          setbackSideSelected(true);
          setBackCropper(config.root + 'Images/Packages/' + response.data.Package.SliderImage2);
          setImgBack(config.root + 'Images/Packages/' + response.data.Package.SliderImage2);

          setfrontSideSelected(true);
          setFrontCropper(config.root + 'Images/Packages/' + response.data.Package.SliderImage1);
          setImgFront(config.root + 'Images/Packages/' + response.data.Package.SliderImage1);


        }

        else {
          setLoadingPackage(false);
        }
      })
      .catch(function (error) {
      });
  }, []);

  const handleCheckboxChange = (e) => {
    setShowPackage(e.target.checked);
  };


  //const MAX_IMAGE_SIZE = 2 * 1024 * 1024; // 2MB in bytes
  const MAX_IMAGE_SIZE = 4096 * 1024; // 2MB in bytes


  const [dragging, setDragging] = useState(false);
  const [dragFront, setdragFront] = useState(false);

  const handleDragOver = (event) => {
    event.preventDefault();
    setDragging(true);
  };
  const handleDragfirst = (event) => {
    event.preventDefault();
    setdragFront(true);
  };


  const handleDropfirst = (event) => {

    event.preventDefault();
    setfrontSideSelected(true);
    setDragging(false);

    const file = event.dataTransfer.files[0];
    if (file) {


      if (file.size > MAX_IMAGE_SIZE) {
        setfrontSideSelected(false)
        toast.error("Image size must be less than 2 MB", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        setImgFront(e.target.result);
        if (backCropper) {
          backCropper.replace(e.target.result);
        }
      };
      reader.readAsDataURL(file);
    }
  };
  const handleDrop = (event) => {

    event.preventDefault();
    setbackSideSelected(true);
    setDragging(false);

    const file = event.dataTransfer.files[0];
    if (file) {

      if (file.size > MAX_IMAGE_SIZE) {

        setbackSideSelected(false)
        toast.error("Image size must be less than 2 MB", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }

      // console.log(file.size);
      const reader = new FileReader();
      reader.onload = (e) => {
        setImgBack(e.target.result);
        if (backCropper) {
          backCropper.replace(e.target.result);
        }
      };
      reader.readAsDataURL(file);
    }
  };


  // front side
  const [ImgFront, setImgFront] = useState("");
  const [frontSideSelected, setfrontSideSelected] = useState(false);
  const [frontCropper, setFrontCropper] = useState();

  const DeleteImgFront = () => {
    setImgFront("");
    setfrontSideSelected(false);
    setFrontCropper();
  }
  // back side
  const [ImgBack, setImgBack] = useState("");
  const [backSideSelected, setbackSideSelected] = useState(false);
  const [backCropper, setBackCropper] = useState();

  const DeleteImgBack = () => {
    setImgBack("");
    setbackSideSelected(false);
    setBackCropper();
  }

  // Passport
  const [ImgPassport, setImgPassport] = useState("");
  const [PassportSideSelected, setPassportSideSelected] = useState(false);
  const [PassportCropper, setPassportCropper] = useState();

  const DeleteImgPassport = () => {
    setImgPassport("");
    setPassportSideSelected(false);
    setPassportCropper();
  }

  // Licence Front
  const [ImgLicenceFront, setImgLicenceFront] = useState("");
  const [LicenceFrontSideSelected, setLicenceFrontSideSelected] = useState(false);
  const [LicenceFrontCropper, setLicenceFrontCropper] = useState();

  const DeleteLicenceFrontSide = () => {
    setLicenceFrontSideSelected(false);
    setLicenceFrontCropper();
    setImgLicenceFront("")
  }

  // back side
  const [ImgLicenceBack, setImgLicenceBack] = useState("");
  const [LicenceBackSideSelected, setLicenceBackSideSelected] = useState(false);
  const [LicenceBackCropper, setLicenceBackCropper] = useState();

  const DeleteImgLicenceBack = () => {
    setImgLicenceBack("");
    setLicenceBackSideSelected(false);
    setLicenceBackCropper();
  }

  const onChangeFront = (e) => {
    e.preventDefault();

    setfrontSideSelected(true);
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    const file = files[0];


    if (file.size > MAX_IMAGE_SIZE) {
      let sizeinkb = file.size / 1024;
      let sizeinmb = sizeinkb / 2024;
      setfrontSideSelected(false);
      toast.error("Image size must be less than 2 MB. Current size is " + sizeinmb.toFixed(2), { position: toast.POSITION.TOP_RIGHT, });
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      setImgFront(reader.result);
    };
    reader.readAsDataURL(files[0]);
  }

  const onChangeBack = (e) => {
    e.preventDefault();
    setbackSideSelected(true);
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    const file = files[0];
    if (file.size > MAX_IMAGE_SIZE) {
      setbackSideSelected(false);
      toast.error("Image size must be less than 2 MB", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }


    const reader = new FileReader();
    reader.onload = () => {
      setImgBack(reader.result);


    };
    reader.readAsDataURL(files[0]);
  }


  const onChangePassport = (e) => {
    e.preventDefault();

    setPassportSideSelected(true);
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    const file = files[0];
    if (file.size > MAX_IMAGE_SIZE) {
      let sizeinkb = file.size / 1024;
      let sizeinmb = sizeinkb / 2024;
      setPassportSideSelected(false);
      toast.error("Image size must be less than 2 MB. Current size is " + sizeinmb.toFixed(2
      ), {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      setImgPassport(reader.result);
    };
    reader.readAsDataURL(files[0]);
  }
  const onChangeFronLicence = (e) => {
    e.preventDefault();

    setLicenceFrontSideSelected(true);
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    const file = files[0];
    if (file.size > MAX_IMAGE_SIZE) {
      let sizeinkb = file.size / 1024;
      let sizeinmb = sizeinkb / 2024;
      setLicenceFrontSideSelected(false);
      toast.error("Image size must be less than 2 MB. Current size is " + sizeinmb.toFixed(2
      ), {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      setImgLicenceFront(reader.result);
    };
    reader.readAsDataURL(files[0]);
  }
  const onChangeBackLicence = (e) => {
    e.preventDefault();

    setLicenceBackSideSelected(true);
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    const file = files[0];
    if (file.size > MAX_IMAGE_SIZE) {
      let sizeinkb = file.size / 1024;
      let sizeinmb = sizeinkb / 2024;
      setLicenceBackSideSelected(false);
      toast.error("Image size must be less than 2 MB. Current size is " + sizeinmb.toFixed(2
      ), {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      setImgLicenceBack(reader.result);
    };
    reader.readAsDataURL(files[0]);
  }


  return (
    <>
      <AdminRightsCheck />
      <ToastContainer />
      <div className="admin-content">

        <div className="row align-items-center gy-3">
          <div className="col-md-6">
            <div className="d-title m-0">
              Update Packages
            </div>
          </div>
          <div className="col-md-6 text-md-end text-center">
            <div className="">
              <NavLink to={`/admin/package/manage-package`}
                className="button button-primary text-white ms-3"
                style={{ fontWeight: "500", display: "inline-flex", alignItems: "center" }}
              >
                Manage Package
                <i className="ri-menu-line ms-3"></i>
              </NavLink>
            </div>
          </div>
        </div>

        <div>
          <div className="card card-table mb-3 mt-4">
            <div className="card-inner">
              <div className="form_default">
                <div className="row align-items-end p-4 gy-3">
                  <div className="col-md-3">
                    <div className="form_default">
                      <div className="form-group">
                        <label htmlFor="">Name</label>
                        <div className="inputwrap">
                          <input
                            type="text"
                            className="form-control"
                            value={Name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form_default">
                      <div className="form-group">
                        <label htmlFor="">Amount</label>
                        <div className="inputwrap">
                          <input
                            type="text"
                            className="form-control"
                            value={Amount}
                            onChange={(e) => setAmount(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form_default">
                      <div className="form-group">
                        <label htmlFor="">ROI</label>
                        <div className="inputwrap">
                          <input
                            type="text"
                            className="form-control"
                            value={ROI}
                            onChange={(e) => setROI(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form_default">
                      <div className="form-group">
                        <label htmlFor="">Package Type</label>
                        <div className="inputwrap">
                          <input
                            type="text"
                            className="form-control"
                            value={PackageType}
                            onChange={(e) => setPackageType(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form_default">
                      <div className="form-group">
                        <label htmlFor="">No of Days</label>
                        <div className="inputwrap">
                          <input
                            type="text"
                            className="form-control"
                            value={NoofDays}
                            onChange={(e) => setNoofDays(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form_default">
                      <div className="form-group">
                        <label htmlFor="">Tag Line</label>
                        <div className="inputwrap">
                          <input
                            type="text"
                            className="form-control"
                            value={TagLine}
                            onChange={(e) => setTagLine(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form_default">
                      <div className="form-group">
                        <label htmlFor="">Direct Bonus</label>
                        <div className="inputwrap">
                          <input
                            type="text"
                            className="form-control"
                            value={DirectBonus}
                            onChange={(e) => setDirectBonus(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form_default">
                      <div className="form-group">
                        <label htmlFor="">Daily Profit Return</label>
                        <div className="inputwrap">
                          <input
                            type="text"
                            className="form-control"
                            value={DailyProfitReturn}
                            onChange={(e) => setDailyProfitReturn(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form_default">
                      <div className="form-group">
                        <label htmlFor="">Monthly Profit</label>
                        <div className="inputwrap">
                          <input
                            type="text"
                            className="form-control"
                            value={MonthlyProfit}
                            onChange={(e) => setMonthlyProfit(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form_default">
                      <div className="form-group">
                        <label htmlFor="">Total Profit Return</label>
                        <div className="inputwrap">
                          <input
                            type="text"
                            className="form-control"
                            value={TotalProfitReturn}
                            onChange={(e) => setTotalProfitReturn(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form_default">
                      <div className="form-group">
                        <label htmlFor="">Product Delivery</label>
                        <div className="inputwrap">
                          <input
                            type="text"
                            className="form-control"
                            value={ProductDelivery}
                            onChange={(e) => setProductDelivery(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form_default">
                      <div className="form-group">
                        <label htmlFor="">Sort Order</label>
                        <div className="inputwrap">
                          <input
                            type="text"
                            className="form-control"
                            value={SortOrder}
                            onChange={(e) => setSortOrder(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <div className="form-check mt-3">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={ShowPackage}
                          onChange={handleCheckboxChange}
                          id="ShowPackage"
                        />
                        <label className="form-check-label" for="ShowPackage">
                          Show Package
                        </label>
                      </div>

                    </div>
                  </div>
                  <div className="col-md-3 text-center">
                    {
                      loading ?
                        <>
                          <Spinner animation="border" role="status"></Spinner>
                        </>
                        :
                        <button
                          className="button w-100 button-primary"
                          onClick={UpdatePackage}
                        >
                          Update Package
                        </button>
                    }

                  </div>
                  <div className="col-md-12">
                    <Editor
                      apiKey={config.tinyapikey}
                      init={{
                        plugins:
                          "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount",
                        toolbar:
                          "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat",
                      }}
                      initialValue={Details}
                      onInit={(editor) => DetailRef.current = editor}
                      onEditorChange={handleDetailChange}
                    />
                  </div>
                  <div className="col-md-12">
                    <ul className="kyc-upload">
                      <li style={{ width: '48%' }}>

                        <div className={`kyc-img-card w-100 white mb-3 `}>
                          <div className="card-inner">
                            <div className={`upload-box ${dragFront ? 'dragFront' : ''}`}
                              id="drop-area"
                              onDragOver={handleDragfirst}
                              onDrop={handleDropfirst}
                            >

                              <input id="ChoosePhotoFront" accept="image/jpeg,image/png" type="file" onChange={onChangeFront} />

                              {
                                frontSideSelected ?
                                  <>
                                    <Cropper
                                      style={{ marginTop: '-10px' }}
                                      aspectRatio={19 / 12}
                                      zoomTo={0}
                                      preview=".img-preview"
                                      src={ImgFront}
                                      dragMode="move"
                                      viewMode={2}
                                      minCropBoxHeight={10}
                                      minCropBoxWidth={10}
                                      background={false}
                                      responsive={true}
                                      autoCropArea={1}
                                      checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                      onInitialized={(instance) => {
                                        setFrontCropper(instance);
                                      }}
                                      guides={true}
                                    />
                                    <div className="delete" onClick={DeleteImgFront}>
                                      <TrashIcon />
                                    </div>
                                  </>
                                  :
                                  <label htmlFor="ChoosePhotoFront" className='thumnail'>
                                    <ArrowUpTrayIcon />
                                    <p>Upload Slider Image 1</p>
                                  </label>

                              }
                            </div>
                          </div>
                        </div>
                      </li>
                      <li style={{ width: '48%' }}>
                        <div className={`kyc-img-card w-100 white `}>
                          <div className="card-inner">
                            <div className={`upload-box ${dragging ? 'dragging' : ''}`}
                              id="drop-area"
                              onDragOver={handleDragOver}
                              onDrop={handleDrop}
                            >

                              <input id="ChoosePhotoBack" accept="image/jpeg,image/png" type="file" onChange={onChangeBack} />

                              {
                                backSideSelected ?
                                  <>
                                    <Cropper
                                      style={{ marginTop: '-10px' }}
                                      aspectRatio={19 / 12}
                                      zoomTo={0}
                                      preview=".img-preview"
                                      src={ImgBack}
                                      dragMode="move"
                                      viewMode={2}
                                      minCropBoxHeight={10}
                                      minCropBoxWidth={10}
                                      background={false}
                                      responsive={true}
                                      autoCropArea={1}
                                      checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                      onInitialized={(instance) => {
                                        setBackCropper(instance);
                                      }}
                                      guides={true}
                                    />
                                    <div className="delete" onClick={DeleteImgBack}>
                                      <TrashIcon />
                                    </div>
                                  </>
                                  :
                                  <label htmlFor="ChoosePhotoBack" className='thumnail'>
                                    <ArrowUpTrayIcon />
                                    <p>Upload Slider Image 2</p>
                                  </label>
                              }
                            </div>
                          </div>
                        </div>
                      </li>
                      <li style={{ width: '48%' }}>
                        <div className={`kyc-img-card w-100 blue mb-3`}>
                          <div className="card-inner">
                            <div className={`upload-box ${dragFront ? 'dragFront' : ''} `}
                              id="drop-area"
                              onDragOver={handleDragfirst}
                              onDrop={handleDropfirst}
                            >

                              <input id="ChooseLicenceFront" accept="image/jpeg,image/png" type="file" onChange={onChangeFronLicence} />


                              {
                                LicenceFrontSideSelected ?
                                  <>
                                    <Cropper
                                      style={{ marginTop: '-10px' }}
                                      aspectRatio={19 / 12}
                                      zoomTo={0}
                                      preview=".img-preview"
                                      src={ImgLicenceFront}
                                      dragMode="move"
                                      viewMode={2}
                                      minCropBoxHeight={10}
                                      minCropBoxWidth={10}
                                      background={false}
                                      responsive={true}
                                      autoCropArea={1}
                                      checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                      onInitialized={(instance) => {
                                        setLicenceFrontCropper(instance);
                                      }}
                                      guides={true}
                                    />
                                    <div className="delete" onClick={DeleteLicenceFrontSide}>
                                      <TrashIcon />
                                    </div>
                                  </>
                                  :
                                  <label htmlFor="ChooseLicenceFront" className='thumnail'>
                                    <ArrowUpTrayIcon />
                                    <p>Upload Slider Image 3</p>
                                  </label>

                              }
                            </div>
                          </div>
                        </div>
                      </li>
                      <li style={{ width: '48%' }}>
                        <div className={`kyc-img-card w-100 blue `}>
                          <div className="card-inner">
                            <div className={`upload-box ${dragging ? 'dragging' : ''}`}
                              id="drop-area"
                              onDragOver={handleDragOver}
                              onDrop={handleDrop}
                            >

                              <input id="ChooseLicenceBack" accept="image/jpeg,image/png" type="file" onChange={onChangeBackLicence} />


                              {
                                LicenceBackSideSelected ?
                                  <>
                                    <Cropper
                                      style={{ marginTop: '-10px' }}
                                      aspectRatio={19 / 12}
                                      zoomTo={0}
                                      preview=".img-preview"
                                      src={ImgLicenceBack}
                                      dragMode="move"
                                      viewMode={2}
                                      minCropBoxHeight={10}
                                      minCropBoxWidth={10}
                                      background={false}
                                      responsive={true}
                                      autoCropArea={1}
                                      checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                      onInitialized={(instance) => {
                                        setLicenceBackCropper(instance);
                                      }}
                                      guides={true}
                                    />
                                    <div className="delete" onClick={DeleteImgLicenceBack}>
                                      <TrashIcon />
                                    </div>
                                  </>
                                  :
                                  <label htmlFor="ChooseLicenceBack" className='thumnail'>
                                    <ArrowUpTrayIcon />
                                    <p>Upload Slider Image 4</p>
                                  </label>
                              }
                            </div>
                          </div>
                        </div>
                      </li>

                      <li style={{ width: '48%' }}>
                        <div className={`kyc-img-card pink `} style={{ width: '400px', height: '400px', minHeight: '400px' }}>
                          <div className="card-inner">
                            <div className={`upload-box ${dragging ? 'dragging' : ''}`}
                              id="drop-area"
                              onDragOver={handleDragOver}
                              onDrop={handleDrop}
                            >

                              <input id="ChoosePassportimage" accept="image/jpeg,image/png" type="file" onChange={onChangePassport} />


                              {
                                PassportSideSelected ?
                                  <>
                                    <Cropper
                                      style={{ width: '400px', minHeight: '400px;', marginTop: '-10px' }}
                                      aspectRatio={4 / 4}
                                      zoomTo={0}
                                      preview=".img-preview"
                                      src={ImgPassport}
                                      dragMode="move"
                                      viewMode={2}
                                      minCropBoxHeight={10}
                                      minCropBoxWidth={10}
                                      background={false}
                                      responsive={true}
                                      autoCropArea={1}
                                      checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                      onInitialized={(instance) => {
                                        setPassportCropper(instance);
                                      }}
                                      guides={true}
                                    />
                                    <div className="delete" onClick={DeleteImgPassport}>
                                      <TrashIcon />
                                    </div>
                                  </>
                                  :
                                  <label htmlFor="ChoosePassportimage" className='thumnail'>
                                    <ViewfinderCircleIcon />
                                    <p>Upload Package Image</p>
                                  </label>
                              }
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UpdatePackage