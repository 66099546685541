import React, { useState, useEffect } from "react";
import config from "../../../Config";
import axios from "axios";
import moment from "moment";
import SupportMenu from "./SupportMenu";
import Logo from "../../../assets/images/Support/Logo.svg";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';
import LoginCheck from "../General/LoginCheck";
import { InboxIcon } from "@heroicons/react/24/outline";
import InboxCounter from "./InboxCounter";
import { toast } from "react-toastify";

function Inbox(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [tickets, setTickets] = useState([]);
    const [ticketType, setTicketType] = useState(null);
    const [loading, setLoading] = useState(false);
    let params = useParams();

    const navigate = useNavigate();
    
    const [TicketID, setTicketId] = useState(0);

    useEffect(() => {

        setLoading(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            Status: "Pending",

        };

        var api_config = {
            method: 'post',
            url: config.base_url + '/Support/GetTicketTypesByStatusMemberID',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                if (response.data.status_code == 1) {
                    setTickets(response.data.ListofTickets);
                    setLoading(false);
                }
                else if (response.data.status_message === "Invalid Access Key.") {
                    navigate("/login");
                    localStorage.clear();
                }
                else {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    setLoading(false);
                }
            })
            .catch(function (error) {
                console.log(error);
            });



    }, []);





    const renderLogs = () => {
        if (tickets != null) {

            let type = params.type;

            if (type == "awaiting") {
                type = "Awaiting Reply";
            }

            let newtickets = tickets.filter(ticket => ticket.Status == type);


            let count = 1;
            return (
                newtickets.map((item, index) => {

                    return (
                        <div className="inbox-item" key={index} style={{ cursor: "pointer" }} onClick={(e) => setTicketId(item.ID)}>
                            <div className="inbox-menu">
                                <InboxIcon />
                                <p>{item.TicketType}</p>
                            </div>
                            <span>{item.NoofTickets}</span>

                        </div>
                    )
                })
            )
        }
    }

    const BackToInbox = () => {
        setTicketId(0);
    }

    return (
        <>

            {
                loading ?
                    <>
                        <Spinner animation="border" role="status"></Spinner>
                    </>
                    :
                    <>
                        {
                            TicketID == 0 ?
                                renderLogs()
                                :
                                <InboxCounter id={TicketID} BackToInbox={BackToInbox} />
                        }
                    </>
            }
        </>
    );
}

export default Inbox;